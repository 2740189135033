<template>
<div>
  <el-cascader
      v-model="value"
      :options="options"
      :props="props"
      @change="handleChange"></el-cascader>
  <el-tabs type="border-card" >
    <el-tab-pane v-for="item in value" :label="item" key="item">{{item}}</el-tab-pane>
  </el-tabs>
<!--  <el-card class="box-card">-->
<!--    <el-row :gutter="10">-->
<!--      <el-col :span="4">-->
<!--        省一级：-->
<!--        <div style="height: calc(100vh - 280px);overflow-y: auto;">-->
<!--          <div v-for="item in level0">-->
<!--            <div style="padding: 2px;margin: 10px;background-color: orange;" @click="level0Click(item.id)">{{item.name}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-col>-->
<!--      <el-col :span="3">-->
<!--        地市级：-->
<!--        <div style="height: calc(100vh - 280px);overflow-y: auto;">-->
<!--          <div v-for="item in level1Temp">-->
<!--            <div style="padding: 2px;margin: 10px;background-color: orange;" @click="level1Click(item.id)">{{item.name}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-col>-->
<!--      <el-col :span="4">-->
<!--        县区级：-->
<!--        <div style="height: calc(100vh - 280px);overflow-y: auto;">-->
<!--          <div v-for="item in level2Temp">-->
<!--            <div style="padding: 2px;margin: 10px;background-color: orange;" @click="level2Click(item.id)">{{item.name}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-col>-->
<!--      <el-col :span="3">-->
<!--        乡镇级：-->
<!--        <div style="height: calc(100vh - 280px);overflow-y: auto;">-->
<!--          <div v-for="item in level3Temp">-->
<!--            <div style="padding: 2px;margin: 10px;background-color: orange;" @click="level3Click(item.id)">{{item.name}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-col>-->
<!--      <el-col :span="10">-->
<!--        简介：-->
<!--        <el-tabs type="border-card" >-->
<!--          <el-tab-pane label="广西壮族自治区">广西壮族自治区</el-tab-pane>-->
<!--          <el-tab-pane label="河池市">河池市</el-tab-pane>-->
<!--          <el-tab-pane label="宜州区">宜州区</el-tab-pane>-->
<!--          <el-tab-pane label="庆远镇">庆远镇</el-tab-pane>-->
<!--        </el-tabs>-->
<!--      </el-col>-->
<!--    </el-row>-->
<!--  </el-card>-->
</div>
</template>
<script>
let id = 0;
    import axios from "axios";
    export default {
        name: 'xzqh',
        data() {
          return {
            value: [],
            options: [],

            props: {
              value: 'name',
              label: 'name',
              // children: '',   // 这里动态
              lazy: false,

            },

            item:{
              name: '成语',
              pinyin: '拼音',
              jbsy: '基本释义',
              xxsy: '详细释义',
              chuchu: '出处',
              liju: '例句',
              jyc: '近义词',
              fyc: '反义词',
            },
            // 数据结构
            //  {     // 这是一个树状数据！
            //    "0": [{"id":"110000","name":"北京市","fid":"0","level_id":"1"},....],
            //    "110000": [{"id":"110100","name":"北京市","fid":"110000","level_id":"2"},.....],  直辖市只有一个地级市
            //    "110100": [{"id":"110101","name":"东城区","fid":"110100","level_id":"3"},.....],  县级
            //    "513334": [{"id":"513334103","name":"拉波镇","fid":"513334","level_id":"4"},...], 乡镇
            //    id: 本级节点  name: 区划名 fid: 父节点id，0为根级  level_id: 区划级，1省 2地 3县 4乡
            //    对象，0: 根数组，xx0000: 省级数组，xxyy00: 地市级数组，xxyyzz: 县级数组（乡级id: xxyyzzmmm）
            jsonData: {},
            idArray: [],
            level0: [],
            level1: [],
            level1Temp: [],
            level2Temp: [],
            level3Temp: [],
            level2: [],
            level3: [],
            level4: [],

            list:[],      // 所有成语队列
            listName: [], // 成语名队列
            listTemp: [], // 搜索到的供选择
            input: '',
          }
        },
        mounted() {
          axios.get('/xzqh_1.0.0.json')
              .then(res => {
                // console.log(res.data)
                this.jsonData = res.data

                this.idArray = Object.keys(this.jsonData)
                // console.log("全部的id数组：",arr[0])
                this.idArray.map((i)=>{ //console.log(i.substring(2,5))
                  if(i==='0'){
                    this.level0 = this.jsonData[i]
                  } else if(i.substring(2,6) === '0000'){
                    this.level1 = this.level1.concat(this.jsonData[i]);
                  }else if(i.substring(4,6) === '00'){
                    this.level2 = this.level2.concat(this.jsonData[i]);
                  }else if(i.length === 6){
                    this.level3 = this.level3.concat(this.jsonData[i]);
                  } else {
                    this.level4 = this.level4.concat(this.jsonData[i]);
                  }

                })
                // console.log("根级别（总省级）：",this.level0)
                // console.log("省级别（总地市）：",this.level1)
                // console.log("地市级（总县区）：",this.level2)
                // console.log("县区级（总乡镇）：",this.level3)
                // console.log("乡镇级：",this.level4)
                // 默认北京
                this.level1Temp1 = []
                var id = this.level0[0].id  // 110000, 下面取 11xx00
                this.idArray.map((i)=> { //console.log(i.substring(2,5))
                  if((i.substring(0,2) === '11') && (i.substring(4,6) === '00')){
                    this.level1Temp = this.level1Temp.concat(this.jsonData[i]);
                  }
                })
                // 封装成联动数据包！
                this.options = this.level0
                this.level0.map((item)=>{
                  item.children=this.jsonData[item.id]
                  this.jsonData[item.id].map((item1)=>{
                    item1.children = this.jsonData[item1.id]
                    this.jsonData[item1.id].map((item2)=>{
                      item2.children = this.jsonData[item2.id]
                    })
                  })
                })
              })
              .catch(error => {
                console.log(error)
              })
        },
        methods: {
          handleChange(value) {
            console.log(value);
            this.request.get('https://baike.baidu.com/item/%E5%AE%9C%E5%B7%9E%E5%8C%BA/20303136?fr=ge_ala').then(res => {
              console.log(res)
            })
            // https://baike.baidu.com/item/%E5%AE%9C%E5%B7%9E%E5%8C%BA/20303136?fr=ge_ala
          },
          level0Click(id){
            this.level1Temp = this.jsonData[id]
            // this.level1Temp = []
            // this.idArray.map((i)=> {
            //   if((i.substring(0,2) === id.substring(0,2)) && (i.substring(4,6) === '00')){
            //     this.level1Temp = this.level1Temp.concat(this.jsonData[i]);
            //   }
            // })
          },
          level1Click(id){
            this.level2Temp = this.jsonData[id]
          },
          level2Click(id){
            this.level3Temp = this.jsonData[id]
          },
          randomClick(){
            let max = this.listName.length
            let index = Math.floor(Math.random() * (max + 1))
            this.itemClick(index)
          },
          itemClick(index){
            this.item.name = this.list[index].name
            this.item.pinyin = this.list[index].pinyin
            this.item.jbsy = this.list[index].jbsy !== 'null' ? JSON.parse(this.list[index].jbsy).join() : ''
            this.item.xxsy = this.list[index].xxsy !== 'null' ? JSON.parse(this.list[index].xxsy).join() : ''
            this.item.chuchu = this.list[index].chuchu
            this.item.liju = this.list[index].liju
            this.item.jyc = this.list[index].jyc !== 'null' ? JSON.parse(this.list[index].jyc).join() : ''
            this.item.fyc = this.list[index].fyc !== 'null' ? JSON.parse(this.list[index].fyc).join() : ''
          },

          handleInput(){
            // 1 获取输入框的值
            // const {value}=e.detail;
            // 2 检测合法性
            if(!this.input.trim()){
              // this.setData({
              //   files:[],
              //   isFocus:false
              // })
              // 值不合法
              return;
            }
            // 3 准备发送请求获取数据
            // this.setData({
            //   isFocus:true
            // })
            clearTimeout(this.TimeId);
            this.TimeId=setTimeout(() => {
              this.qsearch(this.input);
            }, 1); //延迟1秒？
          },
          // 发送请求获取搜索建议 数据
          async qsearch(query){
            var tempFiles=[]

            this.listName.forEach((item,index,arr)=> {
              if(item.name.indexOf(query)!=-1){
                tempFiles.push(item)
              }

            })
            this.listTemp = tempFiles


          },
          // 点击 取消按钮
          handleCancel(){
            this.setData({
              inpValue:"",
              isFocus:false,
              files:[]
            })
          },
        }
    }
</script>
<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.titleBox{
  padding-top: 20px;
}
.subBox{
  height: 100px;
  padding: 10px;
  background-color: grey;
  overflow-y: auto;
}
.itemBox{
  cursor: pointer;
  margin: 2px;
  padding:8px;
  background-color: orange;
}
</style>